import React, { useCallback, useRef, useState } from 'react';
import {
  ActionButton,
  Callout,
  Dropdown,
  FocusZone,
  FocusZoneDirection,
  IDropdownOption,
  IDropdownProps,
  IIconProps,
  Label,
  List,
  SearchBox,
  SelectableOptionMenuItemType,
  Separator,
  Spinner,
  SpinnerSize,
  Stack
} from '@fluentui/react';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FILTER, LOADING_MORE_LOCATIONS, SELECT_PARTNER_LOCATION } from '../../constants/localization';
import { IPartnerLocation } from '../../shared/models/partnerDetailsModel';
import { getCountryLocationMap, getCountryByCode, getLocationDisplay, ILocationNames } from '../../utils/countryStatesUtils';

export interface IPartnerLocationDropDown {
  location: IPartnerLocation;
  allLocations: IPartnerLocation[];
  onLocationChange(location: IPartnerLocation): void;
  width: number;
  maxDropDownHeight: number;
  underLineBorder: boolean;
  label?: string;
  loadingProgress: boolean;
}

// Prefix for the dropdown ID, used for accessibility
const PARTNER_LOCATION_DROPDOWN_ID_PREFIX = 'partner-location-dropdown-';

export default function PartnerLocationDropDown(props: IPartnerLocationDropDown) {
  const dropdownRef = useRef(null);
  const [locationFilter, setLocationFilter] = useState('');
  const MIN_LOCATIONS_FOR_FILTER = 10;
  const filterIconProps: IIconProps = { iconName: 'Filter' };
  const { t: translate } = useTranslation();

  const dropdownId = getUniqueDropdownId(); // Unique dropdown identifier used for accessibility features
  const listWrapperElementId = `${dropdownId}-wrapper`; // Id of the rendered list, used for accessibility (controls) linkage
  const listElementId = `${dropdownId}-list`; // Id of the rendered list, used for accessibility (controls) linkage
  const [isOpen, setIsOpen] = useState(false);

  const onPartnerLocationChange = (event: any, option?: IDropdownOption) => {
    if (event && event.type === 'focus') return;
    if (option) {
      const locations = props.allLocations.filter(locationObj => locationObj.id === option.key);
      if (locations && locations.length > 0) {
        props.onLocationChange(locations[0]);
        setLocationFilter('');
        if (dropdownRef && dropdownRef.current) {
          dropdownRef.current._onDismiss();
        }
      }
    }
  };

  const getLocationsDropDownItems = () => {
    const allLocations: IDropdownOption[] = [];
    const countryLocationsMap: Map<string, IPartnerLocation[]> = getCountryLocationMap(props.allLocations);
    const countryKeysSorted = Array.from(countryLocationsMap.keys()).sort((countryCodeA, countryCodeB) =>
      getCountryByCode(countryCodeA).name > getCountryByCode(countryCodeB).name ? 1 : -1
    );

    let firstCountry = true;
    countryKeysSorted.forEach((countryCode: string) => {
      const countryNames: ILocationNames = getCountryByCode(countryCode);
      const countryLocations = countryLocationsMap.get(countryCode);
      const countryInFilter = locationFilter && countryNames.name.toLocaleLowerCase().indexOf(locationFilter.toLowerCase()) !== -1;
      const locations: IDropdownOption[] = [];
      countryLocations.map(locationObj => {
        //const locationDisplay = getLocationDisplay(locationObj, props.location.id === locationObj.id);
        const locationDisplay = getLocationDisplay(locationObj, true);
        if (
          props.location.id === locationObj.id || // selected item should be displayed regardless of the filter
          countryInFilter || // filter for country --> display all locations
          !locationFilter || // no filter
          locationFilter === '' || // no filter
          locationDisplay.toLocaleLowerCase().indexOf(locationFilter.toLocaleLowerCase()) !== -1 // filter contains location
        )
          locations.push({ key: locationObj.id, text: locationDisplay });
      });
      // No need for divider for the first country.
      if (!firstCountry) {
        allLocations.push({ key: 'divider' + countryCode, text: '-', itemType: SelectableOptionMenuItemType.Divider });
        firstCountry = true;
      }
      if (locations.length > 0) {
        allLocations.push({
          key: 'title' + countryCode,
          text: countryNames.name,
          itemType: SelectableOptionMenuItemType.Header
        });
        allLocations.push(...locations);
      }
    });
    return allLocations;
  };

  const onFilterTextChange = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
    if (newValue !== locationFilter) {
      setLocationFilter(newValue);
    }
  };

  const separatorStyles = {
    root: [
      {
        margin: 0,
        padding: 0,
        maxHeight: 2,
        selectors: {
          '::before': {
            top: '0px'
          }
        }
      }
    ]
  };

  const renderLocationsFilter = () => {
    if (props.allLocations && props.allLocations.length > MIN_LOCATIONS_FOR_FILTER) {
      return (
        <div>
          <div className="filter-search-box">
            <SearchBox
              disableAnimation={true}
              value={locationFilter}
              placeholder={translate(FILTER)}
              iconProps={filterIconProps}
              onChange={onFilterTextChange}
            />
          </div>
          <Separator styles={separatorStyles} />
        </div>
      );
    } else {
      return null;
    }
  };

  const onRenderContainer = (dropdownProps): JSX.Element => {
    return (
      <div>
        <Callout
          calloutWidth={props.width}
          contentEditable={false}
          coverTarget={false}
          hideOverflow={true}
          isBeakVisible={false}
          target={dropdownRef.current._dropDown.current}
          onDismiss={dropdownRef.current._onDismiss}
          setInitialFocus={true}
        >
          <FocusZone direction={FocusZoneDirection.vertical}>
            {renderLocationsFilter()}
            {dropdownProps.onRenderList(dropdownProps)}
          </FocusZone>
        </Callout>
      </div>
    );
  };

  const onRenderList = (dropdownProps): JSX.Element => {
    return (
      <div
        className="filter-search-box-list"
        ref={onListWrapperChange}
        id={listWrapperElementId}
        role="list"
        style={{ maxHeight: props.maxDropDownHeight }}
      >
        <List role="none" items={dropdownProps.options} onRenderCell={onRenderListItem} id={listElementId} />
      </div>
    );
  };

  const onRenderListItem = (option: IDropdownOption): JSX.Element => {
    if (option.itemType === SelectableOptionMenuItemType.Header)
      return (
        <div role="presentation" className="ms-Dropdown-header dropdown-item-header dropdownItemHeader-60">
          <span className="ms-DropdownOptionText dropdownOptionText-59" key={option.key}>
            {option.text}
          </span>
        </div>
      );
    else
      return (
        <div role={'listitem'}>
          <ActionButton
            key={option.key}
            className="action-button"
            onClick={e => {
              onPartnerLocationChange(e, option);
            }}
          >
            <span role={'presentation'}>{option.text}</span>
          </ActionButton>
        </div>
      );
  };

  const getLocationsLabel = (dropDownProps: IDropdownProps): string => {
    if (!props.loadingProgress && props.allLocations && props.allLocations.length > 1) {
      return dropDownProps.label + ' (' + props.allLocations.length + ')';
    } else {
      return dropDownProps.label;
    }
  };

  const renderLabelProgress = (): JSX.Element => {
    if (props.loadingProgress) {
      return <Spinner size={SpinnerSize.small} labelPosition="right" label={translate(LOADING_MORE_LOCATIONS)}></Spinner>;
    } else {
      return null;
    }
  };

  const onRenderLabel = (dropDownProps: IDropdownProps): JSX.Element => {
    if (!props.label) {
      return null;
    } else {
      const labelText = getLocationsLabel(dropDownProps);
      return (
        <Stack horizontal verticalAlign="center">
          <Label className="locations-label">{labelText}</Label>
          {renderLabelProgress()}
        </Stack>
      );
    }
  };

  function getUniqueDropdownId() {
    return uniqueId(PARTNER_LOCATION_DROPDOWN_ID_PREFIX);
  }

  const onListWrapperChange = useCallback(
    listWrapperNode => {
      // Update open state of the dropdown (when no node is provided, assuming a closed state)
      setIsOpen(!!listWrapperNode);
    },
    [dropdownId]
  );

  return (
    <div className="partner-location-box-content">
      <Dropdown
        className={props.underLineBorder ? 'pd-dropdown-underline' : 'pd-dropdown'}
        styles={
          props.underLineBorder
            ? {
                title: {
                  borderWidth: 0,
                  borderBottomWidth: 1, // underline box
                  borderRadius: 0
                },
                dropdown: {
                  boxSizing: 'border-box',
                  height: 34,
                  ':focus::after': {
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    height: 32
                  }
                }
              }
            : null
        }
        label={props.label}
        ariaLabel={translate(SELECT_PARTNER_LOCATION)}
        componentRef={dropdownRef}
        selectedKey={props.location.id}
        key={'contact_partner_location_edit'}
        options={getLocationsDropDownItems()}
        onChange={onPartnerLocationChange}
        onRenderContainer={onRenderContainer}
        onRenderList={onRenderList}
        onRenderLabel={onRenderLabel}
        calloutProps={{ doNotLayer: true }}
        id={dropdownId}
        aria-activedescendant={isOpen ? listElementId : null}
        aria-controls={isOpen ? listWrapperElementId : null}
      />
    </div>
  );
}
