import React from 'react';
import Tile from './tile';
import { IPartner } from '../../shared/models/partnerModel';
import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  IIconProps,
  Label,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  TagItem
} from '@fluentui/react';
import { Trans, useTranslation } from 'react-i18next';
import {
  ALL_RESULTS,
  LOADING_PARTNERS,
  NEXT,
  NO_RESULTS_FOR_CURRENT_FILTER,
  NO_RESULTS_FOR_CURRENT_FILTER_ACTION,
  PREVIOUS,
  RESET_FILTERS,
  PREVIOUS_PAGE,
  NO_MORE_RESULTS_FOUND,
  NO_MORE_RESULTS_FOUND_ACTION,
  CONNECT_WITH_PARTNERS,
  CONNECT_WITH_PARTNERS_DESCRIPTION,
  NO_RESULTS_FOR_LOCATION,
  NO_RESULTS_FOR_LOCATION_ACTION,
  EDIT_LOCATION,
  REMOVE_FILTER,
  PARTNERS_IN_TOTAL,
  PARTNERS_IN_CURRENT_PAGE,
  LAST_PAGE_MESSAGE,
  SORT_BY,
  SORT_BY_MOST_RESPONSIVE,
  SORT_BY_NEAREST_TO_YOU,
  SORT_BY_BEST_MATCH
} from '../../constants/localization';
import { isRtl } from '../../utils/rtlUtils';
import { FilterType } from './partnersGallery';
import { PartnersResultsSort } from '../../shared/models/Request/partnersFilter';

export interface IFilterTag {
  key: string;
  text: string;
  filterType: FilterType;
}

interface IGalleryProps {
  data: IPartner[];
  onClick?: any;
  filterTags: IFilterTag[];
  loadingInProgress: boolean;
  totalResultsCount: number;
  resultsPages: number;
  currentResultsPage: number;
  hasSelectedFilter: boolean;
  hasSelectedLocation: boolean;
  contactPartner: string;
  filterQuryParam: string;
  showBanner: boolean;
  resultSortBy: PartnersResultsSort;
  onRemoveClick(filterTag: IFilterTag): void;
  onPageClick(pageIndex: number): void;
  onSelectLocationClick(): void;
  onClearFiltersClick(): void;
  onSortByChange(sortType: PartnersResultsSort): void;
}

export default function Gallery(props: IGalleryProps) {
  const { t: translate } = useTranslation();

  const renderGalleryBanner = () => {
    if (props.showBanner) {
      return (
        <div className={'gallery-banner-wrapper'}>
          <div className="gallery-banner-content">
            <p className="gallery-banner-title">
              <Trans i18nKey={CONNECT_WITH_PARTNERS}>Connect with partners</Trans>
            </p>
            <p className="gallery-banner-description">
              <Trans i18nKey={CONNECT_WITH_PARTNERS_DESCRIPTION} />
            </p>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderGalleryFilterTags = () => {
    return (
      <div className="gallery-header-tile-filters" role="list">
        {props.filterTags.map((filterTag, index) => {
          const key = filterTag.filterType + '_' + filterTag.key;
          return (
            <TagItem
              removeButtonAriaLabel={translate(REMOVE_FILTER)}
              className="tile-filter"
              key={key}
              index={index}
              item={{ name: filterTag.text, key: key }}
              onRemoveItem={() => (props.onRemoveClick ? props.onRemoveClick(filterTag) : undefined)}
            >
              {decodeHtmlEntities(filterTag.text)}
            </TagItem>
          );
        })}
      </div>
    );
  };

  const decodeHtmlEntities = (rawContent: string): string => {
    const div: HTMLElement = document.createElement('div');
    div.innerHTML = rawContent;
    return div.innerText;
  };

  const renderEmptyPageContent = () => {
    return (
      <div
        className="empty-gallery-container"
        role="alert"
        aria-labelledby="empty-gallery-alert-title"
        aria-describedby="empty-gallery-alert-text"
      >
        <div className={'icon-empty-gallery title-icon'} />
        <div id="empty-gallery-alert-title" className="empty-gallery-title">
          {translate(NO_MORE_RESULTS_FOUND)}
        </div>
        <div id="empty-gallery-alert-text" className="empty-gallery-text">
          {translate(NO_MORE_RESULTS_FOUND_ACTION)}
        </div>
        <PrimaryButton
          className="empty-gallery-button"
          onClick={() => {
            if (props.onPageClick) {
              props.onPageClick(props.currentResultsPage - 1);
            }
          }}
        >
          {translate(PREVIOUS_PAGE)}
        </PrimaryButton>
      </div>
    );
  };

  const renderNoResultsForLocationContent = () => {
    return (
      <div
        className="empty-gallery-container"
        role="alert"
        aria-labelledby="empty-gallery-alert-title"
        aria-describedby="empty-gallery-alert-text"
      >
        <div className={'icon-empty-gallery title-icon'} />
        <div id="empty-gallery-alert-title" className="empty-gallery-title">
          {translate(NO_RESULTS_FOR_LOCATION)}
        </div>
        <div id="empty-gallery-alert-text" className="empty-gallery-text">
          {translate(NO_RESULTS_FOR_LOCATION_ACTION)}
        </div>
        <PrimaryButton
          className="empty-gallery-button"
          onClick={() => {
            if (props.onSelectLocationClick) {
              props.onSelectLocationClick();
            }
          }}
        >
          {translate(EDIT_LOCATION)}
        </PrimaryButton>
      </div>
    );
  };

  const renderEmptyGalleryContent = () => {
    return (
      <div
        className="empty-gallery-container"
        role="alert"
        aria-labelledby="empty-gallery-alert-title"
        aria-describedby="empty-gallery-alert-text"
      >
        <div className={'icon-empty-gallery title-icon'} />
        <div id="empty-gallery-alert-title" className="empty-gallery-title">
          {translate(NO_RESULTS_FOR_CURRENT_FILTER)}
        </div>
        <div id="empty-gallery-alert-text" className="empty-gallery-text">
          {translate(NO_RESULTS_FOR_CURRENT_FILTER_ACTION)}
        </div>
        <PrimaryButton
          className="empty-gallery-button"
          onClick={() => {
            if (props.onClearFiltersClick) {
              props.onClearFiltersClick();
            }
          }}
        >
          {translate(RESET_FILTERS)}
        </PrimaryButton>
      </div>
    );
  };

  const hasPages = () => {
    return hasPreviousPage() || hasNextPage();
  };

  const hasPreviousPage = () => {
    return props.currentResultsPage > 0;
  };

  const hasNextPage = () => {
    return props.currentResultsPage < props.resultsPages - 1;
  };

  const renderSortBox = () => {
    return (
      <div className="gallery-header-sort-box">
        <div className={'icon-sort'} />
        <Label className={isRtl() ? 'gallery-header-sortby-label-rtl' : 'gallery-header-sortby-label'}>{translate(SORT_BY)}</Label>
        <Dropdown
          ariaLabel={translate(SORT_BY)}
          styles={{
            root: { marginTop: 3 },
            title: {
              borderWidth: 0, // underline box
              borderBottomWidth: 0, // underline box
              textAlign: 'left',
              verticalAlign: 'middle'
            },
            dropdown: {
              ':focus::after': {
                borderTopWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0
              }
            }
          }}
          options={[
            { key: PartnersResultsSort.BestMatch, text: translate(SORT_BY_BEST_MATCH), ariaLabel: translate(SORT_BY_BEST_MATCH) },
            {
              key: PartnersResultsSort.MostResponsive,
              text: translate(SORT_BY_MOST_RESPONSIVE),
              ariaLabel: translate(SORT_BY_MOST_RESPONSIVE)
            },
            {
              key: PartnersResultsSort.NearestToYou,
              text: translate(SORT_BY_NEAREST_TO_YOU),
              ariaLabel: translate(SORT_BY_NEAREST_TO_YOU)
            }
          ]}
          selectedKey={props.resultSortBy}
          onChange={(event: any, option?: IDropdownOption) => {
            if (option) {
              props.onSortByChange && props.onSortByChange(option.key as PartnersResultsSort);
            }
          }}
        ></Dropdown>
      </div>
    );
  };

  const renderGalleryResults = () => {
    if (props.loadingInProgress) {
      return (
        <div className="loading-panel">
          <Spinner size={SpinnerSize.large} label={translate(LOADING_PARTNERS)} />
        </div>
      );
    } else if (props.data && props.data.length > 0) {
      // TODO: add this back when backend provide accurate results counter.
      //const resultsTitle = props.totalResultsCount > 0 ? `${translate(ALL_RESULTS)} (${props.totalResultsCount})` : translate(ALL_RESULTS);
      const resultsTitle = translate(ALL_RESULTS);
      return (
        <div className="results-container">
          <Stack horizontal horizontalAlign="space-between">
            <div role="region" aria-live="assertive">
              <div className="results-title">{resultsTitle}</div>
              <div className="invisible-aria-live-label">
                <Trans
                  i18nKey={hasPages() ? PARTNERS_IN_CURRENT_PAGE : PARTNERS_IN_TOTAL}
                  tOptions={{ partnerCount: props.data.length }}
                ></Trans>
                {hasPages() && !hasNextPage() ? <Trans i18nKey={LAST_PAGE_MESSAGE}></Trans> : null}
              </div>
            </div>
            {renderSortBox()}
          </Stack>
          <div className="tiles-container" role="list">
            {props.data.map((partner, index) => (
              <Tile
                key={partner.id + '_' + index}
                partner={partner}
                onClick={props.onClick}
                contactMode={props.contactPartner && partner.id === props.contactPartner}
                filterQuryParam={props.filterQuryParam}
              />
            ))}
          </div>
        </div>
      );
    } else {
      // Empty page results
      if (props.currentResultsPage !== 0) {
        return renderEmptyPageContent();
      }
      // Empty results
      else {
        if (props.filterTags && props.filterTags.length === 0) {
          return renderNoResultsForLocationContent();
        } else {
          return renderEmptyGalleryContent();
        }
      }
    }
  };

  const renderGalleryPages = pageIndexes => {
    {
      // TODO: enable pages display when backend provide accurate results counter.
      return null;
      return pageIndexes.map(pageIndex => {
        const isCurent = pageIndex === props.currentResultsPage;
        const rootStyle = { border: 0, padding: 0 };
        if (isCurent) {
          rootStyle.border = 1;
        }
        return (
          <div key={'div_pageIndex_' + pageIndex} className="page-index-button">
            <DefaultButton
              styles={{
                root: { border: 0, padding: 0 },
                rootChecked: { rootStyle },
                flexContainer: {
                  width: 40
                }
              }}
              key={'pageIndex_' + pageIndex}
              onClick={() => props.onPageClick(pageIndex)}
              checked={isCurent}
              onRenderText={(p, e) => {
                return <span key={'pageIndex_span_' + pageIndex}>{pageIndex + 1}</span>;
              }}
            ></DefaultButton>
          </div>
        );
      });
    }
  };

  const renderGalleryPagination = () => {
    if (props.loadingInProgress) {
      return null;
    }
    if (props.resultsPages <= 1) {
      return null;
    } else {
      let prevValidPages = Math.min(props.currentResultsPage, 2);
      const maxNextValidPages = 2 - prevValidPages + 2;
      let nextValidPages = Math.min(props.resultsPages - 1 - props.currentResultsPage, maxNextValidPages);
      let pageIndexes = [];
      while (prevValidPages-- > 0) {
        pageIndexes.push(props.currentResultsPage - prevValidPages - 1);
      }
      pageIndexes.push(props.currentResultsPage);
      const nextIndexes = [];
      while (nextValidPages-- > 0) {
        nextIndexes.push(props.currentResultsPage + nextValidPages + 1);
      }
      pageIndexes = pageIndexes.concat(nextIndexes.reverse());
      const prevIcon: IIconProps = { iconName: isRtl() ? 'ChevronRight' : 'ChevronLeft' };
      const nextIcon: IIconProps = { iconName: isRtl() ? 'ChevronLeft' : 'ChevronRight', style: { float: 'right' } };
      return (
        <div>
          <div className="results-pagination-panel">
            {hasPreviousPage() ? (
              <DefaultButton
                className="zero-border"
                key="pageIndex_previous"
                iconProps={prevIcon}
                checked={false}
                onClick={() => props.onPageClick(props.currentResultsPage - 1)}
                onRenderText={(p, e) => {
                  return <span>{translate(PREVIOUS)}</span>;
                }}
              />
            ) : null}
            {renderGalleryPages(pageIndexes)}
            {hasNextPage() ? (
              <DefaultButton
                styles={{
                  flexContainer: {
                    flexDirection: 'row-reverse'
                  }
                }}
                className="zero-border"
                key="pageIndex_next"
                iconProps={nextIcon}
                checked={false}
                onClick={() => props.onPageClick(props.currentResultsPage + 1)}
                onRenderText={(p, e) => {
                  return <span>{translate(NEXT)}</span>;
                }}
              ></DefaultButton>
            ) : null}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="gallery-container" role="main">
      {renderGalleryBanner()}
      {renderGalleryFilterTags()}
      {renderGalleryResults()}
      {renderGalleryPagination()}
    </div>
  );
}
