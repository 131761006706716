export const WELCOME_MSG = 'WELCOME_MSG';
export const PARTNER_GALLERY = 'PARTNER_GALLERY';
export const HEBREW = 'HEBREW';
export const ENGLISH = 'ENGLISH';
export const SERVICE = 'SERVICE';
export const CATEGORY = 'CATEGORY';
export const ADVANCED_SPECIALIZATION = 'ADVANCED_SPECIALIZATION';
export const COMPANYSIZE = 'COMPANYSIZE';
export const AGRICULTURE = 'AGRICULTURE';
export const MEDIA_COMMUNICATIONS = 'MEDIA_COMMUNICATIONS';
export const EDUCATION = 'EDUCATION';
export const FINANCIAL_SERVICES = 'FINANCIAL_SERVICES';
export const GOVERNMENT = 'GOVERNMENT';
export const HEALTH_CARE = 'HEALTH_CARE';
export const HOSPITALITY_TRAVEL = 'HOSPITALITY_TRAVEL';
export const NON_PROFIT = 'NON_PROFIT';
export const MANUFACTURING_RESOURCES = 'MANUFACTURING_RESOURCES';
export const POWER_AND_UTILS = 'POWER_AND_UTILS';
export const TRANSPORTATION = 'TRANSPORTATION';
export const PUBLIC_SAFETY_AND_NATIONAL_SECURITY = 'PUBLIC_SAFETY_AND_NATIONAL_SECURITY';
export const RETAIL_CONSUMER_GOODS = 'RETAIL_CONSUMER_GOODS';
export const PROFESSIONAL_SERVICES = 'PROFESSIONAL_SERVICES';
export const DISTRIBUTION = 'DISTRIBUTION';
export const _1_TO_9 = '_1_TO_9';
export const _10_TO_50 = '_10_TO_50';
export const _51_TO_250 = '_51_TO_250';
export const _51_TO_500 = '_51_TO_500';
export const _251_TO_1000 = '_251_TO_1000';
export const _1001_TO_5000 = '_1001_TO_5000';
export const _5001_TO_10000 = '_5001_TO_10000';
export const _10001_TO_20000 = '_10001_TO_20000';
export const _MORE_THAN_20000 = '_MORE_THAN_20000';
export const AZURE = 'AZURE';
export const MICROSOFT_365 = 'MICROSOFT_365';
export const SQL = 'SQL';
export const POWER_PLATFORM = 'POWER_PLATFORM';
export const DYNAMICS_365_BUSINESS = 'DYNAMICS_365_BUSINESS';
export const DYNAMICS_365_ENTERPRISE = 'DYNAMICS_365_ENTERPRISE';
export const ENTERPRISE_MOBILITY_AND_SECURITY = 'ENTERPRISE_MOBILITY_AND_SECURITY';
export const EXCHANGE = 'EXCHANGE';
export const DYNAMICSAX_GP_NAV_SL = 'DYNAMICSAX_GP_NAV_SL';
export const TEAMS = 'TEAMS';
export const OFFICE = 'OFFICE';
export const POWER_BI = 'POWER_BI';
export const PROJECT = 'PROJECT';
export const SHARE_POINT = 'SHARE_POINT';
export const SKYPE_FOR_BUSINESS = 'SKYPE_FOR_BUSINESS';
export const VISIO = 'VISIO';
export const WINDOWS = 'WINDOWS';
export const YAMMER = 'YAMMER';
export const DEPLOYMENT_MIGRATION = 'DEPLOYMENT_MIGRATION';
export const LICENSING = 'LICENSING';
export const CUSTOM_SOLUTION = 'CUSTOM_SOLUTION';
export const IP_SERVICES = 'IP_SERVICES';
export const MANAGED_SERVICES = 'MANAGED_SERVICES';
export const PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT';
export const LEARNING_CERTIFICATION = 'LEARNING_CERTIFICATION';
export const CONSULTING = 'CONSULTING';
export const INTEGRATION = 'INTEGRATION';
export const NETWORKING = 'NETWORKING';
export const STORAGE = 'STORAGE';
export const BACKUP_DISASTER_RECOVERY = 'BACKUP_DISASTER_RECOVERY';
export const MIGRATION = 'MIGRATION';
export const CLOUD_MIGRATION = 'CLOUD_MIGRATION';
export const APPLICATION_INTEGRATION = 'APPLICATION_INTEGRATION';
export const INFORMATION_MANAGEMENT = 'INFORMATION_MANAGEMENT';
export const CLOUD_DB_MIGRATION = 'CLOUD_DB_MIGRATION';
export const THREAT_PROTECTION = 'THREAT_PROTECTION';
export const SMALL_MID_BIZ = 'SMALL_MID_BIZ';
export const HYBRIDCLOUDSECURITY = 'HYBRIDCLOUDSECURITY';
export const AZUREVMWARE = 'AZUREVMWARE';
export const AZUREHYBRIDCLOUDARC = 'AZUREHYBRIDCLOUDARC';
export const AZUREAI = 'AZUREAI';
export const AZUREHYBRIDCLOUDSTACKHCI = 'AZUREHYBRIDCLOUDSTACKHCI';
export const AZUREDEVOPS = 'AZUREDEVOPS';
export const AZURENETWORKING = 'AZURENETWORKING';
export const MODERNIZEENDPOINTS = 'MODERNIZEENDPOINTS';
export const AZURE_ANALYTICS = 'AZURE_ANALYTICS';
export const CUSTOM_TEAMS_SOLUTION = 'CUSTOM_TEAMS_SOLUTION';
export const LOW_CODE_APP = 'LOW_CODE_APP';
export const SQL_SERVER_UPGRADE = 'SQL_SERVER_UPGRADE';
export const ARTIFICIAL_INTELLIGENCE = 'ARTIFICIAL_INTELLIGENCE';
export const ENTERPRISE_BUSINESS_INTELLIGENCE = 'ENTERPRISE_BUSINESS_INTELLIGENCE';
export const DATA_WAREHOUSE = 'DATA_WAREHOUSE';
export const BIG_DATA = 'BIG_DATA';
export const SHARE_POINT_AZURE = 'SHARE_POINT_AZURE';
export const CLOUD_VOICE = 'CLOUD_VOICE';
export const SERVERLESS_COMPUTING = 'SERVERLESS_COMPUTING';
export const HYBRID_STORAGE = 'HYBRID_STORAGE';
export const CHATBOT = 'CHATBOT';
export const DYNAMICS_365_SALES = 'DYNAMICS_365_SALES';
export const DYNAMICS_365_FIELD_SERVICE = 'DYNAMICS_365_FIELD_SERVICE';
export const DYNAMICS_365_CUSTOMER_SERVICE = 'DYNAMICS_365_CUSTOMER_SERVICE';
export const DYNAMICS_365_FINANCE = 'DYNAMICS_365_FINANCE';
export const DYNAMICS_365_RETAIL = 'DYNAMICS_365_RETAIL';
export const DYNAMICS_365_TALENT = 'DYNAMICS_365_TALENT';
export const HIGH_PERFORMANCE_COMPUTING = 'HIGH_PERFORMANCE_COMPUTING';
export const MICRO_SERVICE_APPS = 'MICRO_SERVICE_APPS';
export const REGULATORY_COMPLIANCE_GDPR = 'REGULATORY_COMPLIANCE_GDPR';
export const COMPETATIVE_DATABASE_MIGRATION = 'COMPETATIVE_DATABASE_MIGRATION';
export const MYSQL_POSTGRES_MIGRATION_TO_AZURE = 'MYSQL_POSTGRES_MIGRATION_TO_AZURE';
export const DYNAMICS_ON_AZURE = 'DYNAMICS_ON_AZURE';
export const DATABASE_ON_LINUX = 'DATABASE_ON_LINUX';
export const REDHAT_ON_AZURE = 'REDHAT_ON_AZURE';
export const NOSQL_MIGRATION = 'NOSQL_MIGRATION';
export const INFORMATION_PROTECTION = 'INFORMATION_PROTECTION';
export const AZURESTACK = 'AZURESTACK';
export const DEVOPS = 'DEVOPS';
export const SECURITY = 'SECURITY';
export const IDENTITY_ACCESS_MNGMNT = 'IDENTITY_ACCESS_MNGMNT';
export const WEB_DEVELOPMENT = 'WEB_DEVELOPMENT';
export const MOBILE_APPLICATIONS = 'MOBILE_APPLICATIONS';
export const GAMING = 'GAMING';
export const MEDIA = 'MEDIA';
export const BLOCKCHAIN = 'BLOCKCHAIN';
export const DATABASES = 'DATABASES';
export const ANALYTICS = 'ANALYTICS';
export const AI_MACHINE_LEARNING = 'AI_MACHINE_LEARNING';
export const COGNITIVE_SERVICES = 'COGNITIVE_SERVICES';
export const INTERNET_OF_THINGS = 'INTERNET_OF_THINGS';
export const DEVELOPER_TOOLS = 'DEVELOPER_TOOLS';
export const COMPLIANCE_LEGAL = 'COMPLIANCE_LEGAL';
export const COLLABORATION = 'COLLABORATION';
export const AZURE_EXPERT_MSPS = 'AZURE_EXPERT_MSPS';
export const INFRA_DB_MIGRATE = 'INFRA_DB_MIGRATE';
export const SALES_ASP = 'SALES_ASP';
export const SERVICE_ASP = 'SERVICE_ASP';
export const SUPPLY_CHAIN_ASP = 'SUPPLY_CHAIN_ASP';
export const FINANCE_ASP = 'FINANCE_ASP';
export const INTELLIGENT_AUTOMATION = 'INTELLIGENT_AUTOMATION';
export const BUILD_AND_AI_APP = 'BUILD_AND_AI_APP';
export const BUSINESS_INTELLIGENCE = 'BUSINESS_INTELLIGENCE';
export const SAP_ON_AZURE = 'SAP_ON_AZURE';
export const ADOPTION_CHANGE_MGMT = 'ADOPTION_CHANGE_MGMT';
export const MODERN_WEBAPP = 'MODERN_WEBAPP';
export const CALLING_TEAMS = 'CALLING_TEAMS';
export const MEETING_TEAMS = 'MEETING_TEAMS';
export const KUBERNETES = 'KUBERNETES';
export const DWMIGRATE = 'DWMIGRATE';
export const TEAMWORK_DEPLOYMENT = 'TEAMWORK_DEPLOYMENT';
export const WIN_VIRTUAL_DESKTOP = 'WIN_VIRTUAL_DESKTOP';
export const ID_ACCESS_MANAGEMENT = 'ID_ACCESS_MANAGEMENT';
export const CONTACT_ME = 'CONTACT_ME';
export const ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION';
export const OVERVIEW = 'OVERVIEW';
export const CATEGORIES = 'CATEGORIES';
export const PRODUCTS = 'PRODUCTS';
export const INDUSTRIES = 'INDUSTRIES';
export const SERVICES = 'SERVICES';
export const AZURE_EXPERT_MSP = 'AZURE_EXPERT_MSP';
export const AZURE_EXPERT_MSP_ONLY = 'AZURE_EXPERT_MSP_ONLY';
export const PROVIDER_COMPETENCY_AREA = 'PROVIDER_COMPETENCY_AREA';
export const PROVIDER_COMPETENCY_AREA_WARNING = 'PROVIDER_COMPETENCY_AREA_WARNING';
export const PROVIDER_CERTIFIED_COMPETENCY_AREA = 'PROVIDER_CERTIFIED_COMPETENCY_AREA';
export const LINKS = 'LINKS';
export const LOCATION = 'LOCATION';
export const LOCATIONS = 'LOCATIONS';
export const CANCEL = 'CANCEL';
export const FIRST_NAME = 'FIRST_NAME';
export const LAST_NAME = 'LAST_NAME';
export const WORK_EMAIL = 'WORK_EMAIL';
export const COUNTRY_REGION = 'COUNTRY_REGION';
export const CITY = 'CITY';
export const PHONE = 'PHONE';
export const INDUSTREY_FOCUS = 'INDUSTREY_FOCUS';
export const ORGANIZATION_SIZE = 'ORGANIZATION_SIZE';
export const ORGANIZATION_NAME = 'ORGANIZATION_NAME';
export const ENTER_ORGANIZATION_NAME = 'ENTER_ORGANIZATION_NAME';
export const JOB_TITLE = 'JOB_TITLE';
export const HOW_CAN_WE_HELP = 'HOW_CAN_WE_HELP';
export const SELECT_INDUSTREY_FOCUS = 'SELECT_INDUSTREY_FOCUS';
export const SELECT_ORGANIZATION_SIZE = 'SELECT_ORGANIZATION_SIZE';
export const WE_PULLED_YOUR_PROFILE_INFO_MESSAGE = 'WE_PULLED_YOUR_PROFILE_INFO_MESSAGE';
export const FILTER = 'FILTER';
export const PARTNER_LOCATION = 'PARTNERS_LOCATION';
export const MORE = 'MORE';
export const HARDWARE = 'HARDWARE';
export const CLOSE = 'CLOSE';
export const CONTACT_SUCCESS_DIALOG_TITLE = 'CONTACT_SUCCESS_DIALOG_TITLE';
export const CONTACT_SUCCESS_DIALOG_MESSAGE_PREFIX = 'CONTACT_SUCCESS_DIALOG_MESSAGE_PREFIX';
export const CONTACT_SUCCESS_DIALOG_MESSAGE_SUFFIX = 'CONTACT_SUCCESS_DIALOG_MESSAGE_SUFFIX';
export const ZIP_CODE = 'ZIP_CODE';
export const STATE = 'STATE';
export const LOADING_MORE_LOCATIONS = 'LOADING_MORE_LOCATIONS';
export const APPLY = 'APPLY';
export const FILTER_DISTANCE_NEARBY = 'FILTER_DISTANCE_NEARBY';
export const FILTER_DISTANCE_MIDRANGE = 'FILTER_DISTANCE_MIDRANGE';
export const FILTER_DISTANCE_FAR = 'FILTER_DISTANCE_FAR';
export const KILOMETERS_ABBRV = 'KILOMETERS_ABBRV';
export const MILES_ABBRV = 'MILES_ABBRV';
export const RADIUS = 'RADIUS';
export const RADIUS_TITLE = 'RADIUS_TITLE';
export const ADDRESS = 'ADDRESS';
export const ADDRESS_CITY_OR_COUNTRY = 'ADDRESS_CITY_OR_COUNTRY';
export const SELECT_LOCATION = 'SELECT_LOCATION';
export const EDIT_LOCATION = 'EDIT_LOCATION';
export const SEARCH_FILTERS = 'SEARCH_FILTERS';
export const PARTNERS_FROM_THIS_LOCATION = 'PARTNERS_FROM_THIS_LOCATION';
export const PARTNERS_FROM_ENTIRE_COUNTRY = 'PARTNERS_FROM_ENTIRE_COUNTRY';
export const FILTERS = 'FILTERS';
export const CLEAR_ALL = 'CLEAR_ALL';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const MICROSOFT_ENDORSEMENTS = 'MICROSOFT_ENDORSEMENTS';
export const DIVERSE_BUSINESS = 'DIVERSE_BUSINESS';
export const DIVERSE_BUSINESS_DESCRIPTION = 'DIVERSE_BUSINESS_DESCRIPTION';
export const PROVIDER_WITH_COMPETENCIES = 'PROVIDER_WITH_COMPETENCIES';
export const BROWSE_SERVICE_PARTNERS = 'BROWSE_SERVICE_PARTNERS';
export const FILTER_SORT_REFINE_RESULTS = 'FILTER_SORT_REFINE_RESULTS';
export const SORT_BY = 'SORT_BY';
export const SORT_BY_BEST_MATCH = 'SORT_BY_BEST_MATCH';
export const SORT_BY_MOST_RESPONSIVE = 'SORT_BY_MOST_RESPONSIVE';
export const SORT_BY_NEAREST_TO_YOU = 'SORT_BY_NEAREST_TO_YOU';
export const NEXT = 'NEXT';
export const PREVIOUS = 'PREVIOUS';
export const DESCRIBE_YOUR_BUSINESS_NEED = 'DESCRIBE_YOUR_BUSINESS_NEED';
export const ALL_RESULTS = 'ALL_RESULTS';
export const LOADING_PARTNERS = 'LOADING_PARTNERS';
export const LOADING = 'LOADING';
export const PREVIOUS_PAGE = 'PREVIOUS_PAGE';
export const RESET_FILTERS = 'RESET_FILTERS';
export const NO_RESULTS_FOR_CURRENT_FILTER = 'NO_RESULTS_FOR_CURRENT_FILTER';
export const NO_RESULTS_FOR_CURRENT_FILTER_ACTION = 'NO_RESULTS_FOR_CURRENT_FILTER_ACTION';
export const NO_RESULTS_LOCATION_NOT_SELECTED = 'NO_RESULTS_LOCATION_NOT_SELECTED';
export const NO_RESULTS_LOCATION_NOT_SELECTED_ACTION = 'NO_RESULTS_LOCATION_NOT_SELECTED_ACTION';
export const NO_MORE_RESULTS_FOUND = 'NO_MORE_RESULTS_FOUND';
export const NO_MORE_RESULTS_FOUND_ACTION = 'NO_MORE_RESULTS_FOUND_ACTION';
export const AZURE_EXPERT_MSPS_EXPLANATION = 'AZURE_EXPERT_MSPS_EXPLANATION';
export const ADVANCED_SPECIALIZATION_EXPLANATION = 'ADVANCED_SPECIALIZATION_EXPLANATION';
export const SURFACE = 'SURFACE';
export const SURFACE_HUB = 'SURFACE_HUB';
export const CONNECT_WITH_PARTNERS = 'CONNECT_WITH_PARTNERS';
export const CONNECT_WITH_PARTNERS_DESCRIPTION = 'CONNECT_WITH_PARTNERS_DESCRIPTION';
export const WHERE_ARE_YOU_LOCATED = 'WHERE_ARE_YOU_LOCATED';
export const USE_MY_LOCATION = 'USE_MY_LOCATION';
export const SEARCH_PARTNERS = 'SEARCH_PARTNERS';
export const TYPE_LOCATION_INSTRUCTIONS = 'TYPE_LOCATION_INSTRUCTIONS';
export const USER_LOCATION_DISABLED_IN_BROWSER = 'USER_LOCATION_DISABLED_IN_BROWSER';
export const ALLOW_ACCESS_TO_MY_CURRENT_LOCATION = 'ALLOW_ACCESS_TO_MY_CURRENT_LOCATION';
export const NO_LOCATION_SELECTED = 'NO_LOCATION_SELECTED';
export const INVALID_VALUE = 'INVALID_VALUE';
export const SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG';
export const MY_LOCATION = 'MY_LOCATION';
export const NO_RESULTS_FOR_LOCATION = 'NO_RESULTS_FOR_LOCATION';
export const NO_RESULTS_FOR_LOCATION_ACTION = 'NO_RESULTS_FOR_LOCATION_ACTION';
export const PARTNERS = 'PARTNERS';
export const PARTNER_LOGO_ALT = 'PARTNER_LOGO_ALT';
export const EXPAND = 'EXPAND';
export const COLLAPSE = 'COLLAPSE';
export const EXPAND_ORGANIZATION_SIZE_FILTER = 'EXPAND_ORGANIZATION_SIZE_FILTER';
export const EXPAND_INDUSTRIES_FILTER = 'EXPAND_INDUSTRIES_FILTER';
export const EXPAND_PRODUCTS_FILTER = 'EXPAND_PRODUCTS_FILTER';
export const EXPAND_CATEGORIES_FILTER = 'EXPAND_CATEGORIES_FILTER';
export const EXPAND_SERVICES_FILTER = 'EXPAND_SERVICES_FILTER';
export const EXPAND_MICROSOFT_ENDORSEMENTS_FILTER = 'EXPAND_MICROSOFT_ENDORSEMENTS_FILTER';
export const COLLAPSE_ORGANIZATION_SIZE_FILTER = 'COLLAPSE_ORGANIZATION_SIZE_FILTER';
export const COLLAPSE_INDUSTRIES_FILTER = 'COLLAPSE_INDUSTRIES_FILTER';
export const COLLAPSE_PRODUCTS_FILTER = 'COLLAPSE_PRODUCTS_FILTER';
export const COLLAPSE_CATEGORIES_FILTER = 'COLLAPSE_CATEGORIES_FILTER';
export const COLLAPSE_SERVICES_FILTER = 'COLLAPSE_SERVICES_FILTER';
export const COLLAPSE_MICROSOFT_ENDORSEMENTS_FILTER = 'COLLAPSE_MICROSOFT_ENDORSEMENTS_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const CLOSE_LOCATION_SELECTION_DIALOG = 'CLOSE_LOCATION_SELECTION_DIALOG';
export const CLOSE_CONTACT_PARTNER_DIALOG = 'CLOSE_CONTACT_PARTNER_DIALOG';
export const SELECT_PARTNER_LOCATION = 'SELECT_PARTNER_LOCATION';
export const RIGHT_ARROW = 'RIGHT_ARROW';
export const AUTOCOMPLETE_RESULT_IN_TYPE = 'AUTOCOMPLETE_RESULT_IN_TYPE';
export const PARTNERS_IN_TOTAL = 'PARTNERS_IN_TOTAL';
export const PARTNERS_IN_CURRENT_PAGE = 'PARTNERS_IN_CURRENT_PAGE';
export const LAST_PAGE_MESSAGE = 'LAST_PAGE_MESSAGE';
export const SHOWING_SEARCH_RESULT_FOR = 'SHOWING_SEARCH_RESULT_FOR';
export const NO_LOCATION_SEARCH_ALERT_MESSAGE = 'NO_LOCATION_SEARCH_ALERT_MESSAGE';
export const NO_LOCATION_SEARCH_ALERT_ACTION = 'NO_LOCATION_SEARCH_ALERT_ACTION';
export const INVALID_VALUE_NO_URLS_ALLOWED = 'INVALID_VALUE_NO_URLS_ALLOWED';

// Diverisity general
export const DIVERSE_OWNED = 'DIVERSE_OWNED';
export const DIVERSE_ACCREDIATION = 'DIVERSE_ACCREDIATION';
export const DIVERSE_OWNED_DESCRIPTION = 'DIVERSE_OWNED_DESCRIPTION';
export const DIVERSE_ACCREDIATION_DESCRIPTION = 'DIVERSE_ACCREDIATION_DESCRIPTION';
export const DIVERSE_OWNED_TITLE = 'DIVERSE_OWNED_TITLE';
export const DIVERSE_ACCREDIATION_TITLE = 'DIVERSE_ACCREDIATION_TITLE';
export const LEARN_MORE = 'LEARN_MORE';
export const COLLAPSE_DIVERSE_BUSINESS_FILTER_FILTER = 'COLLAPSE_DIVERSE_BUSINESS_FILTER_FILTER';
export const EXPAND_DIVERSE_BUSINESS_FILTER = 'EXPAND_DIVERSE_BUSINESS_FILTER';
export const SOCIAL_IMPACT = 'SOCIAL_IMPACT';
export const COLLAPSE_SOCIAL_IMPACT_FILTER = 'COLLAPSE_SOCIAL_IMPACT_FILTER';
export const EXPAND_SOCIAL_IMPACT_FILTER = 'EXPEND_SOCIAL_IMPACT_FILTER';

// Diverisity own
export const DIVERSITY_OWNED_AFRICANAMERICANORBLACK = 'DIVERSITY_OWNED_AFRICANAMERICANORBLACK';
export const DIVERSITY_OWNED_HISPANICLATINO = 'DIVERSITY_OWNED_HISPANICLATINO';
export const DIVERSITY_OWNED_ASIAN = 'DIVERSITY_OWNED_ASIAN';
export const DIVERSITY_OWNED_MIDDLEEASTERNNORTHAFRICAN = 'DIVERSITY_OWNED_MIDDLEEASTERNNORTHAFRICAN';
export const DIVERSITY_OWNED_NATIVEHAWAIIANPACIFICISLANDER = 'DIVERSITY_OWNED_NATIVEHAWAIIANPACIFICISLANDER';
export const DIVERSITY_OWNED_NATIVEAMERICANALASKANATIVE = 'DIVERSITY_OWNED_NATIVEAMERICANALASKANATIVE';
export const DIVERSITY_OWNED_DISABILITY = 'DIVERSITY_OWNED_DISABILITY';
export const DIVERSITY_OWNED_LGBTQ = 'DIVERSITY_OWNED_LGBTQ';
export const DIVERSITY_OWNED_WOMAN = 'DIVERSITY_OWNED_WOMAN';
export const DIVERSITY_OWNED_VETERAN = 'DIVERSITY_OWNED_VETERAN';

// Diverisity led
export const DIVERSITY_LED_AFRICANAMERICANORBLACK = 'DIVERSITY_LED_AFRICANAMERICANORBLACK';
export const DIVERSITY_LED_HISPANICLATINO = 'DIVERSITY_LED_HISPANICLATINO';
export const DIVERSITY_LED_ASIAN = 'DIVERSITY_LED_ASIAN';
export const DIVERSITY_LED_MIDDLEEASTERNNORTHAFRICAN = 'DIVERSITY_LED_MIDDLEEASTERNNORTHAFRICAN';
export const DIVERSITY_LED_NATIVEHAWAIIANPACIFICISLANDER = 'DIVERSITY_LED_NATIVEHAWAIIANPACIFICISLANDER';
export const DIVERSITY_LED_NATIVEAMERICANALASKANATIVE = 'DIVERSITY_LED_NATIVEAMERICANALASKANATIVE';
export const DIVERSITY_LED_DISABILITY = 'DIVERSITY_LED_DISABILITY';
export const DIVERSITY_LED_LGBTQ = 'DIVERSITY_LED_LGBTQ';
export const DIVERSITY_LED_WOMAN = 'DIVERSITY_LED_WOMAN';
export const DIVERSITY_LED_VETERAN = 'DIVERSITY_LED_VETERAN';

// Diverisity accredeation
export const DIVERSITY_ACCRED_USSBAHUBZONE = 'DIVERSITY_ACCRED_USSBAHUBZONE';
export const DIVERSITY_ACCRED_DISABILITYIN = 'DIVERSITY_ACCRED_DISABILITYIN';
export const DIVERSITY_ACCRED_NATIONALLGBTCHAMBEROFCOMMERCE = 'DIVERSITY_ACCRED_NATIONALLGBTCHAMBEROFCOMMERCE';
export const DIVERSITY_ACCRED_NATIONALMINORITYSUPPLIERDEVELOPMENTCOUNCIL = 'DIVERSITY_ACCRED_NATIONALMINORITYSUPPLIERDEVELOPMENTCOUNCIL';
export const DIVERSITY_ACCRED_NATIONALVETERANBUSINESSDEVELOPMENTCOUNCIL = 'DIVERSITY_ACCRED_NATIONALVETERANBUSINESSDEVELOPMENTCOUNCIL';
export const DIVERSITY_ACCRED_USSBA8A = 'DIVERSITY_ACCRED_USSBA8A';
export const DIVERSITY_ACCRED_USSBASMALLDISADVANTAGEDBUSINESS = 'DIVERSITY_ACCRED_USSBASMALLDISADVANTAGEDBUSINESS';
export const DIVERSITY_ACCRED_USSBAWOMANOWNEDSMALLBUSINESS = 'DIVERSITY_ACCRED_USSBAWOMANOWNEDSMALLBUSINESS';
export const DIVERSITY_ACCRED_USSBASERVICEDISABLEDVETERANOWNEDSMALLBUSINESS =
  'DIVERSITY_ACCRED_USSBASERVICEDISABLEDVETERANOWNEDSMALLBUSINESS';
export const DIVERSITY_ACCRED_WOMENSBUSINESSENTERPRISENATIONALCOUNCIL = 'DIVERSITY_ACCRED_WOMENSBUSINESSENTERPRISENATIONALCOUNCIL';
export const DIVERSITY_ACCRED_OTHERCERTAGENCY = 'DIVERSITY_ACCRED_OTHERCERTAGENCY';
export const CONTACT_PARTNER_DISCLAIMER_MESSAGE = 'CONTACT_PARTNER_DISCLAIMER_MESSAGE';
export const LINKEDIN_PAGE = 'LINKEDIN_PAGE';
export const DESIGNATIONS = 'DESIGNATIONS';
export const DESIGNATIONS_SHORT = 'DESIGNATIONS_SHORT';
export const TRAINING_DESIGNATIONS = 'TRAINING_DESIGNATIONS';
export const TRAINING_DESIGNATIONS_SHORT = 'TRAINING_DESIGNATIONS_SHORT';
export const DESIGNATIONS_EXPLANATION = 'DESIGNATIONS_EXPLANATION';
export const TRAINING_DESIGNATIONS_EXPLANATION = 'TRAINING_DESIGNATIONS_EXPLANATION';
export const DESIGNATIONS_DIGITAL_AND_APP_INNOVATION_KEY = 'DESIGNATIONS_DIGITAL_AND_APP_INNOVATION_KEY';
export const DESIGNATIONS_INFRASTRUCTURE_KEY = 'DESIGNATIONS_INFRASTRUCTURE_KEY';
export const DESIGNATIONS_DATA_AND_AI_KEY = 'DESIGNATIONS_DATA_AND_AI_KEY';
export const DESIGNATIONS_MODERN_WORK_INNOVATION_KEY = 'DESIGNATIONS_MODERN_WORK_INNOVATION_KEY';
export const DESIGNATIONS_SECURITY_INNOVATION_KEY = 'DESIGNATIONS_SECURITY_INNOVATION_KEY';
export const DESIGNATIONS_BUSINESS_APP_KEY = 'DESIGNATIONS_BUSINESS_APP_KEY';
export const ADV_SPEC_PARENT_AZURE = 'ADV_SPEC_PARENT_AZURE';
export const ADV_SPEC_PARENT_MODERN_WORK = 'ADV_SPEC_PARENT_MODERN_WORK';
export const ADV_SPEC_PARENT_SECURITY = 'ADV_SPEC_PARENT_SECURITY';
export const ADV_SPEC_PARENT_BUSINESS_APPS = 'ADV_SPEC_PARENT_BUSINESS_APPS';
export const SPECIALIZATION = 'SPECIALIZATION';
export const SPECIALIZATION_EXPLANATION = 'SPECIALIZATION_EXPLANATION';
export const TECH_SOLUTIONS_SERVICE_PARENT = 'TECH_SOLUTIONS_SERVICE_PARENT';
export const RESELLERS_SERVICES_SERVICE_PARENT = 'RESELLERS_SERVICES_SERVICE_PARENT';
export const PROFESSIONAL_SERVICES_SERVICE_PARENT = 'PROFESSIONAL_SERVICES_SERVICE_PARENT';
export const SYSTEM_INTEGRATION = 'SYSTEM_INTEGRATION';
export const DESIGNATION_IN_SOLUTION = 'DESIGNATION_IN_SOLUTION';
export const DESIGNATION_IN_TRAINING = 'DESIGNATION_IN_TRAINING';
export const PARTNER_NAME = 'PARTNER_NAME';
export const MORE_PRODUCTS = 'MORE_PRODUCTS';
